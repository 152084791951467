import React, { useState, useEffect } from "react";
import { Container, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import NewsCarousel from "./NewsCarousel";
import { uploadFile } from './Utils';
import LogoUploader from "./LogoUploader";
import Editor from "./Editor";
import { generateGUIDv4 } from "./Utils";
import ConfirmModal from "./ConfirmModal";
import { Spinner } from "react-bootstrap";
import ApiHost from "./ApiHost";

const NewsPage = ({ news, jwtToken, quillRef, environment }) => {
    const [selectedNews, setSelectedNews] = useState({});
    const [newsData, setNewsData] = useState(news);
    const [newItemAdded, setNewItemAdded] = useState(false);
    const [itemDeleted, setItemDeleted] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [showPublishConfirm, setShowPublishConfirm] = useState(false);
    const [loadingPublish, setLoadingPublish] = useState(false);
    useEffect(() => {
        setNewsData(prevNewsData => 
            prevNewsData.map(item => ({
              ...item,
              id: generateGUIDv4() // Replace the id of each item with a new GUID
            })));
        setSelectedNews(news[0]);
    }, [news]);

    const handlePublishNews = async () => {  
        try {
          let queryString = environment === 'Staging' ? 'staging=1' : '';
          const response = await fetch(`${ApiHost}/v1/publish-news?${queryString}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwtToken}`, // Pass the token in the Authorization header
            },
            body: JSON.stringify(newsData),
          });
    
          if (response.ok) {
            setLoadingPublish(false);
            console.log('News published successfully');
            setHasChanges(false);
          } else {
            console.error('Failed to publish news');
          }
        } catch (error) {
          console.error('Error updating news:', error);
        }
      };

    const handleInputChangeInData = (key, value, dontUpdateSelectedNews = false) => {
        setSelectedNews((prevNewsData) => {
            const updatedData = { ...prevNewsData, [key]: value };

            // Update newsData in the same state update to ensure synchronization
            setNewsData((prevData) =>
                prevData.map((oldNews) =>
                    oldNews.id === prevNewsData.id ? updatedData : oldNews
                )
            );
            setHasChanges(true);
            // Return the updated selected news for the state update
            return !dontUpdateSelectedNews ? updatedData : prevNewsData;
        });
    };

    const handleUploadedLogoSave = (url) => {
        handleInputChangeInData('img_src', url);
    };

    const handleRemoveArticle = () => {
        if (newsData.length > 1) {
            setNewsData((prevData) => prevData.filter(item => item.id !== selectedNews.id));
            setSelectedNews(newsData.find(item => item.id !== selectedNews.id));
            setItemDeleted(true);
            setHasChanges(true);
            //reset it after the effect in NewsCarousel has run:
            setTimeout(() => setItemDeleted(false), 0);
        }
    };

    // Function to handle adding a new article
    const handleAddArticle = () => {
        const newArticle = {
            id: generateGUIDv4(),
            title: '',
            description: '',
            source: '',
            img_src: '',
            html_content: ''
        };

        setNewsData(prevData => [...prevData, newArticle]);
        setSelectedNews(newArticle);
        setNewItemAdded(true);
        setHasChanges(true);
        //reset it after the effect in NewsCarousel has run:
        setTimeout(() => setNewItemAdded(false), 0);
    };

    const handlePublish = () => {
        setLoadingPublish(true);
        setShowPublishConfirm(true)
      };
      const handlePublishConfirm = () => {
        setShowPublishConfirm(false);
        setHasChanges(false);
        handlePublishNews();
      };
      const handlePublishCancel = () => {
        setLoadingPublish(false);
        setShowPublishConfirm(false);
      };

    // Tooltip for the remove button
    const removeTooltip = (
        <Tooltip id="remove-article-tooltip">
            You can't remove the last article
        </Tooltip>
    );

    return (
        <Container>
            <div style={{ position: 'relative', top: '1rem' }}>
                {hasChanges && (
                  <div className="text-center">
                    <ConfirmModal
                      show={showPublishConfirm}
                      onConfirm={handlePublishConfirm}
                      onCancel={handlePublishCancel}
                      message={`Are you sure you want to publish to ${environment}.`}
                    />
                    <Button disabled={loadingPublish} onClick={handlePublish} className="btn btn-sm text-white bg-success position-relative">
                      {loadingPublish ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Publish'
                      )}
                    </Button>
                  </div>
                )}
              </div>
            <NewsCarousel news={newsData} setSelectedItem={setSelectedNews} newItemAdded={newItemAdded} itemDeleted={itemDeleted}/>
            <div className="d-flex">
                <div className="p-3" style={{ width: "50%" }}>
                    <div className="mb-3">
                        <label className="form-label"><strong>Title</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            value={selectedNews.title || ''}
                            onChange={(e) => handleInputChangeInData('title', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"><strong>Description</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            value={selectedNews.description || ''}
                            onChange={(e) => handleInputChangeInData('description', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"><strong>Source</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            value={selectedNews.source || ''}
                            onChange={(e) => handleInputChangeInData('source', e.target.value)}
                        />
                    </div>
                    <div className="py-1 px-4">
                        <LogoUploader
                            data={selectedNews}
                            handleInputChange={handleInputChangeInData}
                            uploadFile={uploadFile}
                            jwtToken={jwtToken}
                            handleUploadedLogoSave={handleUploadedLogoSave}
                            showRemove={false}
                        />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <Button variant="success" onClick={handleAddArticle}>Add Article</Button>
                        <div className="mx-2"></div> {/* This creates a gap between buttons */}
                        <OverlayTrigger
                            placement="top"
                            overlay={newsData.length <= 1 ? removeTooltip : <></>}
                        >
                            <Button variant="danger" onClick={handleRemoveArticle}>Remove Article</Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="p-3" style={{ width: "50%" }}>
                    <div className="mb-3">
                        <label className="form-label"><strong>Popup Details</strong></label>
                        <Editor
                            ref={quillRef}
                            defaultValue={selectedNews.html_content || ''}
                            readOnly={false}
                            onTextChange={(htmlContent) => handleInputChangeInData('html_content', htmlContent, true)}
                            onSelectionChange={(range, oldRange, source) => { }}
                        />
                    </div>
                </div>
            </div>
        </Container >
    );
};

export default NewsPage;