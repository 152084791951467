import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";

const NewsCarousel = ({ news, handleMenuShow, setSelectedItem, newItemAdded, itemDeleted }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (news.length > 0) {
      if (isFirstLoad.current) {
        setSelectedItem(news[0]);
        isFirstLoad.current = false;
      } else if (newItemAdded || itemDeleted) {
        const newIndex = news.length - 1;
        setSelectedItem(news[newIndex]);
        setActiveIndex(newIndex);
      }
    }
  }, [news, setSelectedItem, newItemAdded, itemDeleted]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
    setSelectedItem(news[selectedIndex]);
  };

  return (
    <Container fluid>
      <h1 className="py-4 text-center">Preview</h1>
      <Carousel 
        interval={null} 
        controls={true} 
        activeIndex={activeIndex} 
        onSelect={handleSelect}
      >
        {news.map((newsItem, index) => (
          <Carousel.Item key={newsItem.id || index}>
            <Container className="text-center" fluid>
              <Row className="justify-content-center">
                <Col md={10}>
                  <Card className="p-3 text-white news-carousel">
                    <Row className="align-items-center h-100">
                      <Col md={8}>
                        <Card.Body className="d-flex flex-column justify-content-center h-100">
                          <Card.Title className="fw-bold">
                            <Button variant="light">{newsItem.title}</Button>
                          </Card.Title>
                          <Card.Text>{newsItem.description}</Card.Text>
                          <Card.Text>
                            <strong>Source</strong>: {newsItem.source}
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col className="news-img" md={4}>
                        <Card.Img
                          variant="top"
                          src={newsItem.img_src}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default NewsCarousel;